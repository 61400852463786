

#id_payment_mode.form-control{
 list-style-type: None;
  display: inline-block;
  width: 100% ;
  height: auto;
}


#id_payment_mode  input[type="radio"], input[type="checkbox"] {
 display: inline;
 width: auto;
 height: revert;

}

.navbar-accounts .container-fluid .navbar-brand {
  background: url("../img/ui/dashboard/logo_oscar.png") no-repeat scroll 0 0 transparent;
  text-indent: -9999px;
  display: block;
  width: 430px;
  height: 80px;
  padding: 0;
  margin-left: 0;
  margin-top: 5px;
}

// Product page

.product_pod {
    height: 350px;
    overflow: hidden;
    text-align: center;
    margin: 20px 0;
    position: relative;
    min-width: 200px;
}

.product_pod p,
.product_pod h3,
.product_pod h2,
.product_pod form {
    margin-bottom: 0;
}

.product_pod h3 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    margin-bottom: 0;
}

.product_pod h3 small,
.product_pod h3 .small {
    font-weight: normal;
    line-height: 1;
    color: #777777;
}

.product_pod h3 small,
.product_pod h3 .small {
    font-size: 75%;
}

.product_pod .price_color {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #5cb85c;
}

.product_pod .price_color small,
.product_pod .price_color .small {
    font-weight: normal;
    line-height: 1;
    color: #777777;
}

.product_pod .price_color small,
.product_pod .price_color .small {
    font-size: 75%;
}

.product_pod .availability,
.product_pod .price_color {
    min-height: 30px;
    white-space: unset;
}

.product_pod .image_container,
.product_pod .availability,
.product_pod .price_color {
    margin-bottom: 10px;
}

.product_pod .product_price {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.image_container {
    width: 100%;
    min-height: 165px;
    overflow: hidden;
    text-align: center;
    position: relative;
}

.image_container img {
    max-height: 155px;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
}

a:hover .thumbnail {
    border-color: #428bca;
    -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}

.availability.outofstock {
    color: #d9534f;
}

.availability.instock {
    color: #5cb85c;
}

.price_color {
    display: block;
    color: #5cb85c;
    font-weight: bold;
}

.product_main .price_color {
    font-size: 26px;
}

.product_pod .price_color {
    margin-top: 0;
}

.es-carousel .price_color {
    font-size: 20px;
}

#add_to_basket_form {
  width: auto;
}


#add_to_basket_form #id_child_id.form-control{
  display: block;
  width: 100% ;
  height: auto;
}

#add_to_basket_form div.form-group div  .form-control {
  display: inline-block;
  width: unset ;
  height: auto;
}

.checkout-quantity .input-group {
  max-width: 200px;
}
